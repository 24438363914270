import { BaseTextFieldProps, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import HolzfinderInput from "./HolzfinderInput";

interface HolzfinderNumberInputProps extends BaseTextFieldProps {
  unit: string;
  onChange?: (event: any) => void;
  validateInput?:  (value: string) => boolean;
  formatInput?: (value: string) => string;
  disabled?: boolean;
  value: number | string | null
}

export default function HolzfinderNumberInput(props: HolzfinderNumberInputProps) {
  const { unit, disabled, ...rest } = props;
  const validateInput = props.validateInput || ((value: string) => true);
  const formatInput = props.formatInput || ((value: string) => value);
  const [editingValue, setEditingValue] = useState(props.value);
  const [lastValidInput, setLastValidInput] = useState<string | number | null>(props.value);
  const handleChange = (event: any) => {
    validateInput(event.target.value) && setLastValidInput(event.target.value);
    setEditingValue(formatInput(event.target.value));
  };
  const handleBlur = () => {
    if(editingValue !== null && typeof editingValue === 'string'  && !validateInput(editingValue)) {
      setEditingValue(lastValidInput)
      props.onChange && props.onChange(lastValidInput);
    } else {
      props.onChange && props.onChange(editingValue);
    }
  };
  useEffect(() => {
    setEditingValue(props.value);
  }, [props.value]);

  const newProperties = { ...rest };
  delete newProperties.onChange;
  return (
    <HolzfinderInput
      {...newProperties}
      value={editingValue}
      onInput={handleChange}
      type="number"
      onBlur={handleBlur}
      disabled={disabled}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
    />
  );
}
