import { Slider, SliderTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { useState, useRef, useEffect } from "react";

export default function HolzfinderSlider(props: DefaultComponentProps<SliderTypeMap>) {
  const { onChange, ...other } = props;
  const [sliderValue, setSliderValue] = useState<number | number[]>(props.value ?? 0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const debounceTime = 500; // Adjust the debounce time (in milliseconds) as needed

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    setSliderValue(newValue);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange(event, sliderValue, activeThumb);
      }
      timeoutRef.current = null;
    }, debounceTime);
  };

  return (
    <Slider
      {...other}
      value={sliderValue}
      onChange={handleChange}
      sx={{
        color: "#005851",

        "& .MuiSlider-track": {
          color: "#C0D904",
        },
      }}
    />
  );
}
