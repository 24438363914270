import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AxiosResponseHeaders } from "axios";
import { UserGeoLocationInterface } from "interfaces/GeoLocationInterface";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { RequestStatus } from "interfaces/RequestStatus";
import { fetchLotLocation, fetchLotLocations } from "./fetchLotLocations";


export interface SortKindInterface {
  // way:  string,
  // field: string,
  label: string,
}

export const SortKinds: SortKindInterface[] = [
  {
    // way: "",
    // field: "",
    label: "Sortierung"
  },
  {
    // way: "+",
    // field: "date_created",
    label: "Neueste Zuerst"
  },
  {
    // way: "-",
    // field: "date_created",
    label: "Älteste zuerst"
  },
  {
    // way: "+",
    // field: "price / cm",
    label: "Günstigste zuerst"
  },
]

export interface LotOfferFilterSettingsInterface {
  wood_types: string[];
  sellers: string[];
  counties: string[];
  diameter: [number, number];
  solid_cubic_meters_without_bark: [number, number];
  price_per_solid_cubic_meter: [number, number];
  average_length_value: [number, number];
}

export interface lotListState {
  status: RequestStatus;
  sort: number;
  location: string;
  distance: number | undefined;
  userLocation: UserGeoLocationInterface;
  wood_piles?: { latitude: number, longitude: number };
  lotLocations: LotOfferLocationInterface[];
  filterSettings: LotOfferFilterSettingsInterface;
  filter: LotOfferFilterSettingsInterface;
  lotLocationsFiltered: LotOfferLocationInterface[];
}

const initialFilterSettings: LotOfferFilterSettingsInterface = {
  wood_types: [],
  sellers: [],
  counties: [""],
  diameter: [0, 100],
  solid_cubic_meters_without_bark: [0, 100],
  price_per_solid_cubic_meter: [0, 100],
  average_length_value: [0, 100],
}

export const StuttgartLocation = { longitude: 9.1816, latitude: 48.7841 };

const initialState: lotListState = {
  status: RequestStatus.idle,
  sort: 0,
  location: "Stuttgart",
  distance: undefined,
  lotLocations: [],
  userLocation: StuttgartLocation,
  wood_piles: undefined,

  filterSettings: initialFilterSettings,
  filter: initialFilterSettings,
  lotLocationsFiltered: []
};


function getMaxOf(list: any, field: any): number {
  if (list.length === 0) return 0;
  return Math.ceil(Number(list.reduce((prev: any, current: any) => (Number(prev[field]) > Number(current[field])) ? prev : current)[field]));
}
function getMinOf(list: any, field: any): number {
  if (list.length === 0) return 0;
  return Math.floor(Number(list.reduce((prev: any, current: any) => (Number(prev[field]) < Number(current[field])) ? prev : current)[field]));
}
function uniqOf(list: any, field: any): string[] {
  if (list.length === 0) return [];
  return list.reduce((accumulator: any, current: any) => {
    let val = current[field];
    if (val && !accumulator.includes(val)) {
      accumulator.push(val)
    }
    return accumulator;
  }, []
  )
}
function collectUniqOf(list: any, field: any): string[] {
  if (list.length === 0) return [];
  return list.reduce((accumulator: any, current: any) => {
    current[field].forEach((el: string) => {
      if (!accumulator.includes(el)) {
        accumulator.push(el)
      }
    })
    return accumulator;
  }, []
  )
}

function findCommonElements(arr1: string[], arr2: string[]): boolean {
  return arr1.some(item => arr2.includes(item))
}
function inRange(val: number, range: [number, number]): boolean {
  return val >= range[0] && val <= range[1];
}

function filterLotLocations(locs: LotOfferLocationInterface[], filter: LotOfferFilterSettingsInterface, sort: number): LotOfferLocationInterface[] {

  let list = locs.filter(function (loc) {
    // console.debug({wood_types: filter.wood_types.length === 0, sellers_length: filter.sellers.length === 0, counties_length: filter.counties.length === 0, diameter: inRange(loc.diameter, filter.diameter), solid_cubic_meters_without_bark: inRange(loc.solid_cubic_meters_without_bark, filter.solid_cubic_meters_without_bark), price_per_solid_cubic_meter: inRange(loc.price_per_solid_cubic_meter, filter.price_per_solid_cubic_meter), average_length_value: inRange(loc.average_length_value, filter.average_length_value)});
    // console.debug({filter_test: inRange(loc.diameter, filter.diameter), filter_value: filter.diameter, loc_value: loc.diameter});
    return (
      (filter.wood_types.length === 0 || findCommonElements(filter.wood_types, loc.wood_types)) &&
      (filter.sellers.length === 0 || filter.sellers.includes(loc.seller)) &&
      (filter.counties.length === 0 || filter.counties[0] === "" || filter.counties.includes(loc.county)) &&
      inRange(loc.diameter, filter.diameter) &&
      inRange(loc.solid_cubic_meters_without_bark, filter.solid_cubic_meters_without_bark) &&
      inRange(loc.price_per_solid_cubic_meter, filter.price_per_solid_cubic_meter) &&
      inRange(loc.average_length_value, filter.average_length_value)
    );
  });
  // TODO sort elements
  if (sort === 1) {
    // date_created +
    return list.sort((a, b) => a.published_at_date - b.published_at_date);
  } else if (sort === 2) {
    // date_created -
    return list.sort((a, b) => b.published_at_date - a.published_at_date);
  } else if (sort === 3) {
    // price_per_solid_cubic_meter / fm
    return list.sort((a, b) => a.price_per_solid_cubic_meter - b.price_per_solid_cubic_meter);
  }
  return list;
}

// export const getLotLocations = createAsyncThunk("lotLocations/fetchLotLocations", async (_args, { getState }) => {
//   const state = getState() as RootState;
//   const { distance, location } = state.lotLocations;
//   // console.log("Distance:", distance);
//   if (distance !== null && location !== "") {
//     const response = await fetchLotLocations({ distance, location });
//     
//     return { headers: response.headers, data: response.data };
//   } else {
//     // Fallback for "alle" or empty location
//     const response = await fetchAllLotLocations(); // Implement fetchAllLotLocations
//     return { headers: response.headers, data: response.data };
//   }
// });

export const getLotLocations = createAsyncThunk("lotLocations/fetchLotLocations", async (_args, { getState }) => {
  const state = getState() as RootState;
  const { distance, location } = state.lotLocations;
  console.log("Distance:", distance);
  const requestDistance = distance || undefined;
  const requestLocation = location || initialState.location;
  const response = await fetchLotLocations({ distance: requestDistance, location: requestLocation });
  return { headers: response.headers, data: response.data };
});
export const getLotLocation = createAsyncThunk("lotLocations/fetchLotLocation", async (id: string, { getState }): Promise<{ headers: AxiosResponseHeaders; data: LotOfferLocationInterface }> => {
  const { lotLocations } = getState() as RootState;
  const { location } = lotLocations;
  const response = await fetchLotLocation({ location, id });
  return { headers: response.headers, data: response.data.data };
});

export const lotLocationsSlice = createSlice({
  name: "lotLocations",
  initialState,
  reducers: {
    clear: (_state) => {
      return initialState;
    },
    clearResults: (state) => {
      state.lotLocations = [];
    },
    clearFilters: (state) => {
      state.filter = {
        ...state.filterSettings,
        wood_types: [],
        sellers: [],
        counties: [""],
      };
      // state.distance = 0;
      state.location = "";
      state.lotLocationsFiltered = filterLotLocations(state.lotLocations, state.filter, state.sort);
    },
    clearHighlightMarkerLocation: (state) => {
      state.wood_piles = undefined;
    },
    setSort: (state, action: PayloadAction<number>) => {
      state.sort = action.payload;
    },

    setDistance: (state, action: PayloadAction<number | undefined>) => {
      state.distance = action.payload;
    },
    setLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setSellers: (state, action: PayloadAction<string[]>) => {
      state.filter = { ...state.filter, sellers: action.payload };
    },
    setCounties: (state, action: PayloadAction<string[]>) => {
      state.filter = { ...state.filter, counties: action.payload };
      
    },
    setLength: (state, action: PayloadAction<[number, number]>) => {
      state.filter = { ...state.filter, average_length_value: action.payload };
      
    },
    setPrice: (state, action: PayloadAction<[number, number]>) => {
      state.filter = { ...state.filter, price_per_solid_cubic_meter: action.payload };
      
    },
    setFm: (state, action: PayloadAction<[number, number]>) => {
      state.filter = { ...state.filter, solid_cubic_meters_without_bark: action.payload };
      
    },
    setDiameter: (state, action: PayloadAction<[number, number]>) => {
      state.filter = { ...state.filter, diameter: action.payload };
      
    },
    setWoodTypes: (state, action: PayloadAction<string[]>) => {
      state.filter = { ...state.filter, wood_types: action.payload };
      
    },
    setHighlightedMarkerLocation: (state, action: PayloadAction<any>) => {
      state.wood_piles = state.wood_piles || { latitude: 0, longitude: 0 };
      if (state.wood_piles.latitude !== action.payload.latitude || state.wood_piles.longitude !== action.payload.longitude) {
        state.wood_piles = action.payload;
      }
    },
    recalculateFilteredLotLocations: (state) => {
      state.lotLocationsFiltered = filterLotLocations(state.lotLocations, state.filter, state.sort);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLotLocations.pending, (state) => {
        state.status = RequestStatus.loading;
      })

      .addCase(getLotLocations.fulfilled, (state, action) => {
        state.userLocation = action.payload.data.data.user ? action.payload.data.data.user : { longitude: undefined, latitude: undefined };
        state.status = RequestStatus.idle;
        state.lotLocations = action.payload.data.data.lot_offers.map((e) => {
          e.published_at_date = Date.parse(e.published_at);
          return e;
        });


        state.filterSettings = {
          wood_types: collectUniqOf(state.lotLocations, "wood_types"),
          sellers: uniqOf(state.lotLocations, "seller"),
          counties: uniqOf(state.lotLocations, "county"),
          diameter: [getMinOf(state.lotLocations, "diameter"), getMaxOf(state.lotLocations, "diameter")],
          solid_cubic_meters_without_bark: [getMinOf(state.lotLocations, "solid_cubic_meters_without_bark"), getMaxOf(state.lotLocations, "solid_cubic_meters_without_bark")],
          price_per_solid_cubic_meter: [getMinOf(state.lotLocations, "price_per_solid_cubic_meter"), getMaxOf(state.lotLocations, "price_per_solid_cubic_meter")],
          average_length_value: [getMinOf(state.lotLocations, "average_length_value"), getMaxOf(state.lotLocations, "average_length_value")],
        };
        state.filter = {
          wood_types: [],
          sellers: [],
          counties: [],
          diameter: [...state.filterSettings.diameter],
          solid_cubic_meters_without_bark: [...state.filterSettings.solid_cubic_meters_without_bark],
          price_per_solid_cubic_meter: [...state.filterSettings.price_per_solid_cubic_meter],
          average_length_value: [...state.filterSettings.average_length_value],
        };
        
      })
      .addCase(getLotLocations.rejected, (state) => {
        state.status = RequestStatus.failed;
      })
      .addCase(updateLotLocationsFiltered.fulfilled, (state, action) => {
        state.lotLocationsFiltered = action.payload;
      })
      .addCase(updateLotLocationsFiltered.rejected, (state, action) => {
        console.error("updateLotLocationsFiltered rejected", action.error);
      })
      .addMatcher(
        (action) => action.type.startsWith('lotLocations/') && !action.type.includes('updateLotLocationsFiltered'),
        (state, action) => {
          if (action.type !== 'lotLocations/getLotLocations/fulfilled') {
            lotLocationsSlice.caseReducers.recalculateFilteredLotLocations(state);
          }
        }
      )

  },
});

export const updateLotLocationsFiltered = createAsyncThunk(
  "lotLocations/updateLotLocationsFiltered",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { lotLocations, filter, sort } = state.lotLocations;
    return filterLotLocations(lotLocations, filter, sort);
  }
);

export const { clear, clearResults, setSort, setDistance, setLocation, setSellers, setCounties, setLength, setPrice, setFm, setDiameter, setWoodTypes, clearFilters, setHighlightedMarkerLocation, clearHighlightMarkerLocation, recalculateFilteredLotLocations } =
  lotLocationsSlice.actions;

export const selectLotLocations = (state: RootState): lotListState => state.lotLocations;

export const selectLotLocationsList = (state: RootState) =>
  state.lotLocations.lotLocations;

export const selectLotLocationsSort = (state: RootState) =>
  state.lotLocations.sort;

export const selectLotLocationsUserLocation = (state: RootState) =>
  state.lotLocations.userLocation;

export const selectLotLocationDistance = (state: RootState) =>
  state.lotLocations.distance;

export const selectLotLocationLocationString = (state: RootState) =>
  state.lotLocations.location;

export const selectLotLocationFilter = (state: RootState) =>
  state.lotLocations.filter;

export const selectLotLocationFilterSettings = (state: RootState) =>
  state.lotLocations.filterSettings;

export const selectLotLocationsFiltered = (state: RootState) =>
  // Check if a filter is set
  state.lotLocations.filter !== state.lotLocations.filterSettings
    ? state.lotLocations.lotLocationsFiltered
    : state.lotLocations.lotLocations;

export const selectLotLocationsWoodPileLocation = (state: RootState) =>
  state.lotLocations.wood_piles;


export default lotLocationsSlice.reducer;
